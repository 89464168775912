import {
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getWatersContent = async (docId) => {
  const subcollectionRef = collection(db, "waters", docId, docId + "Data");
  const subcollectionSnapshot = await getDocs(subcollectionRef);
  const subcollectionData = subcollectionSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return subcollectionData;
};

export { getWatersContent };
