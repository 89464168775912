import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  deleteObject as deleteStorageObject,
} from "firebase/storage";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getAllCatches = async () => {
  const querySnapshot = await getDocs(collection(db, "catches"));
  const catchesData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return catchesData;
};

export { getAllCatches };

const getPublicCatches = async () => {
  const querySnapshot = await getDocs(collection(db, "catches"));
  const catchesData = querySnapshot.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .filter((catchData) => catchData.isPublic === true);

  return catchesData;
};

export { getPublicCatches };

const approveCatch = async (id) => {
  try {
    await updateDoc(doc(db, "catches", id), {
      isPublic: true,
    });
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

export { approveCatch };

const deleteCatch = async (id) => {
  try {
    const catchDocRef = doc(db, "catches", id);
    const catchDoc = await getDoc(catchDocRef);

    if (catchDoc.exists()) {
      await deleteDoc(catchDocRef);

      const imgURL = catchDoc.data().imgURL;

      if (imgURL) {
        const storage = getStorage();
        const photoRef = ref(storage, imgURL);

        await deleteStorageObject(photoRef);
      }
    } else {
      console.log("Catch not found.");
    }
  } catch (error) {
    console.error("Error deleting catch: ", error);
    throw error;
  }
};

export { deleteCatch };

const postCatch = async (formData) => {
  try {
    const catchData = {};
    const storage = getStorage();
    const photoFile = formData.get("photoFile");

    formData.forEach((value, key) => {
      if (key !== "photoFile") {
        catchData[key] = value;
      }
    });

    catchData.isPublic = false;

    const docRef = await addDoc(collection(db, "catches"), catchData);

    const storageRef = ref(storage, "catches/" + docRef.id);

    await uploadBytes(storageRef, photoFile);

    const downloadURL = await getDownloadURL(storageRef);

    await updateDoc(doc(db, "catches", docRef.id), {
      imgURL: downloadURL,
    });
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export { postCatch };

// TODO:
// Causing a lot of reads each time the count is checked as it's mapping over every document.
// Either move unapproved to a different collection or set a state somewhere so it only checks if > 0
const getUnapprovedSumittions = async () => {
  try {
    const collectionRef = collection(db, "catches");
    const q = query(collectionRef, where("isPublic", "==", false));

    const querySnapshot = await getDocs(q);

    const numberOfDocuments = querySnapshot.size;

    return numberOfDocuments;
  } catch (error) {
    console.error("Error getting number of documents:", error);
    throw error;
  }
};

export { getUnapprovedSumittions };
