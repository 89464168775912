import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getSocials } from "../../services/socialsService";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RedditIcon from "@mui/icons-material/Reddit";
import "./NavBar.css";
import AdminAuth from "../AdminAuth/AdminAuth";

const WatersMenu = ({ showOnMobile }) => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;

  return (
    <div
      className={`waters-menu ${
        showOnMobile || screenWidth > 768 ? "show" : ""
      }`}
    >
      {showOnMobile || screenWidth > 768 ? (
        <ul>
          <li>
            <NavLink to="/waters/weston">Weston</NavLink>
          </li>
          <li>
            <NavLink to="/waters/warrens-green">Warrens Green</NavLink>
          </li>
          <li>
            <NavLink to="/waters/clay-end">Clay End</NavLink>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

const NavBar = () => {
  const currentRoute = useLocation().pathname.toLowerCase();
  const [socialsData, setSocialsData] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const iconComponents = {
    "Facebook": FacebookIcon,
    "X (Formerly Twitter)": XIcon,
    "Instagram": InstagramIcon,
    "YouTube": YouTubeIcon,
    "LinkedIn": LinkedInIcon,
    "Reddit": RedditIcon,
  };

  useEffect(() => {
    const fetchData = async () => {
      const collectionData = await getSocials();
      setSocialsData(collectionData);
    };

    fetchData();
  }, []);

  const handleWatersHover = () => {
    setMenuOpen(true);
  };

const handleNavLinkClick = (e) => {
  // If the clicked element is the "Waters" nav link and it's not the dropdown trigger or a child element within the dropdown,
  // prevent the default behavior
  if (e.target.closest(".waters") && !e.target.closest(".waters-menu")) {
    e.preventDefault();
  }
  // Close the menu
  setMenuOpen(false);
};

  const renderIcon = (socialName) => {
    const IconComponent = iconComponents[socialName];
    if (IconComponent) {
      return <IconComponent className="icon" />;
    }
    return null;
  };

  return (
    <div className={`navbar-container ${isMenuOpen ? "menu-open" : ""}`}>
      <nav>
        <div className="menu-toggle" onClick={() => setMenuOpen(!isMenuOpen)}>
          ☰
        </div>
        <ul className={isMenuOpen ? "menu-open" : ""}>
          <li>
            <div>
              <a href="/" className="logo">
                PAC
              </a>
            </div>
          </li>
          <li>
            <NavLink
              to="/"
              className={
                currentRoute === "/" && !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/our-people"
              className={
                currentRoute.includes("about") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Our People
            </NavLink>
          </li>
          <li
            className="dropdown"
            onMouseEnter={handleWatersHover}
            onMouseLeave={() => setMenuOpen(false)}
          >
            <div className="waters">
              <NavLink
                to="/waters"
                className={
                  (currentRoute.includes("waters") &&
                    !currentRoute.includes("admin")) ||
                  (currentRoute.includes("weston") &&
                    !currentRoute.includes("admin")) ||
                  (currentRoute.includes("warrens-green") &&
                    !currentRoute.includes("admin")) ||
                  (currentRoute.includes("clay-end") &&
                    !currentRoute.includes("admin"))
                    ? "active"
                    : "NavLink"
                }
                onClick={handleNavLinkClick}
              >
                Waters
              </NavLink>
              <WatersMenu showOnMobile={isMenuOpen} />
            </div>
          </li>
          <li>
            <NavLink
              to="/rules"
              className={
                currentRoute.includes("rules") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Rules
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/matches"
              className={
                currentRoute.includes("matches") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Matches
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/newsletter"
              className={
                currentRoute.includes("newsletter") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Newsletter
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/catches"
              className={
                currentRoute.includes("catches") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Catches
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-us"
              className={
                currentRoute.includes("contact") &&
                !currentRoute.includes("admin")
                  ? "active"
                  : "NavLink"
              }
              onClick={handleNavLinkClick}
            >
              Contact Us
            </NavLink>
          </li>
          <AdminAuth redirectToHome={false}>
            <li>
              <NavLink
                to="/admin"
                className={
                  currentRoute.includes("admin") ? "active" : "NavLink"
                }
                onClick={handleNavLinkClick}
              >
                Admin
              </NavLink>
            </li>
          </AdminAuth>

          <div className="icons">
            {socialsData.map(
              (social) =>
                social.socialActive && (
                  <li key={social.id}>
                    <a
                      href={social.socialURL}
                      className="NavLink icon-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {renderIcon(social.socialName)}
                    </a>
                  </li>
                )
            )}
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
