import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Home/Home";
import OurPeople from "./components/OurPeople/OurPeople";
import Waters from "./components/Waters/Waters";
import ClayEnd from "./components/Waters/ClayEnd/ClayEnd";
import WarrensGreen from "./components/Waters/WarrensGreen/WarrensGreen";
import Weston from "./components/Waters/Weston/Weston";
import Rules from "./components/Rules/Rules";
import Matches from "./components/Matches/Matches";
import NewsLetter from "./components/Newsletter/Newsletter";
import Catches from "./components/Catches/Catches";
import ContactUs from "./components/ContactUs/ContactUs";
import Login from "./components/admin/Login/Login";
import Admin from "./components/admin/Admin";
import ManageCommittee from "./components/admin/ManageCommittee/ManageCommittee";
import CreateMember from "./components/admin/ManageCommittee/create/Create";
import MemberDetails from "./components/admin/ManageCommittee/[id]/index";
import ManageMatches from "./components/admin/ManageMatches/ManageMatches";
import EditRules from "./components/admin/ManageMatches/EditRules/EditRules";
import ManageCatches from "./components/admin/ManageCatches/ManageCatches";
import ManageContent from "./components/admin/ManageContent/ManageContent";
import ManageAdmins from "./components/admin/ManageAdmins/ManagerAdmins";
import ManageSocials from "./components/admin/ManageSocials/ManageSocials";
import ManageContact from "./components/admin/ManageContact/ManageContact";
import ManageWaters from "./components/admin/ManageWaters/ManagerWaters";

const routes = [
  { path: "/", component: Home },
  { path: "/our-people", component: OurPeople },
  { path: "/waters", component: Waters },
  { path: "/waters/clay-end", component: ClayEnd },
  { path: "/waters/warrens-green", component: WarrensGreen },
  { path: "/waters/weston", component: Weston },
  { path: "/rules", component: Rules },
  { path: "/matches", component: Matches },
  { path: "/newsletter", component: NewsLetter },
  { path: "/catches", component: Catches },
  { path: "/contact-us", component: ContactUs },
  { path: "/admin/login", component: Login },
  { path: "/admin", component: Admin },
  { path: "/admin/manage-committee", component: ManageCommittee },
  { path: "/admin/manage-committee/create", component: CreateMember },
  { path: "/admin/manage-committee/:id", component: MemberDetails },
  { path: "/admin/manage-matches", component: ManageMatches },
  { path: "/admin/manage-matches/match-rules", component: EditRules },
  { path: "/admin/manage-catches", component: ManageCatches },
  { path: "/admin/manage-content", component: ManageContent },
  { path: "/admin/manage-admins", component: ManageAdmins },
  { path: "/admin/manage-socials", component: ManageSocials },
  { path: "/admin/manage-contacts", component: ManageContact },
  { path: "/admin/manage-waters", component: ManageWaters },
];

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
