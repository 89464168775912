import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import AdminAuth from "../../AdminAuth/AdminAuth";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deletePermissionsUser,
  postPermissionsUser,
  getPermissions,
  putPermissionsUser,
} from "../../../services/permissionsService";

const ManageAdmins = () => {
  const isLocalhost = window.location.hostname === "localhost";
  const apiUrl = isLocalhost
    ? "http://localhost:3001"
    : "https://pac-ui-server.onrender.com";

  const [newAdmin, setNewAdmin] = useState({
    adminEmail: "",
    adminDisplayName: "",
    adminPassword: "",
    confirmAdminPassword: "",
    disabled: false,
  });

  const [permissions, setPermissions] = useState({
    manageCatches: false,
    manageCommittee: false,
    manageContent: false,
    manageMatches: false,
    manageSocials: false,
    manageContacts: false,
    manageWaters: false,
    superAdmin: false,
  });

  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiUrl}/listAllUsers`);
      const data = await response.json();
      setLoading(false);
      setUsers(data);
    } catch (error) {
      console.error("Error listing all users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "disabled") {
      setNewAdmin((prevAdmin) => ({
        ...prevAdmin,
        [name]: checked,
      }));
    } else {
      setNewAdmin((prevAdmin) => ({
        ...prevAdmin,
        [name]: value,
      }));
    }
  };

  const handleCreateAdmin = async () => {
    if (newAdmin.adminPassword === newAdmin.confirmAdminPassword) {
      try {
        const response = await fetch(`${apiUrl}/createAdmin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: newAdmin.adminEmail,
            password: newAdmin.adminPassword,
            displayName: newAdmin.adminDisplayName,
            disabled: newAdmin.disabled,
          }),
        });

        const data = await response.json();

        await postPermissionsUser(data.uid, permissions);

        fetchUsers();
      } catch (error) {
        console.error("Error creating new user:", error);
      }

      setNewAdmin({
        adminEmail: "",
        adminDisplayName: "",
        adminPassword: "",
        confirmAdminPassword: "",
        disabled: false,
      });
      handleClose();
    }
  };

  const updateUser = async () => {
    try {
      const requestBody = {
        displayName: newAdmin.adminDisplayName,
        email: newAdmin.adminEmail,
        disabled: newAdmin.disabled,
        password: newAdmin.confirmAdminPassword
      };

      const response = await fetch(`${apiUrl}/updateUser/${selectedUser.uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      await putPermissionsUser(selectedUser.uid, permissions);

      if (response.ok) {
        fetchUsers();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }

    setNewAdmin({
      adminEmail: "",
      adminDisplayName: "",
      adminPassword: "",
      confirmAdminPassword: "",
      disabled: false,
    });
    handleClose();
  };

  const deleteUser = async (uid) => {
    try {
      const response = await fetch(`${apiUrl}/deleteUser/${uid}`, {
        method: "DELETE",
      });
      if (response.ok) {
        await deletePermissionsUser(uid);
        fetchUsers();
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEditUser = async (user) => {
    const perms = await getPermissions(user.uid);

    setSelectedUser(user);
    setNewAdmin({
      adminEmail: user.email,
      adminDisplayName: user.displayName,
      adminPassword: "",
      confirmAdminPassword: "",
      disabled: user.disabled,
    });
    setPermissions({
      manageCatches: perms.manageCatches || false,
      manageCommittee: perms.manageCommittee || false,
      manageContent: perms.manageContent || false,
      manageMatches: perms.manageMatches || false,
      manageSocials: perms.manageSocials || false,
      manageContacts: perms.manageContacts || false,
      manageWaters: perms.manageWaters || false,
      superAdmin: perms.superAdmin || false,
    });
    handleOpen();
    setIsEditing(true);
  };

  useEffect(() => {
    if (!open) {
      setPermissions({
        manageCatches: false,
        manageCommittee: false,
        manageContent: false,
        manageMatches: false,
        manageSocials: false,
        manageContacts: false,
        manageWaters: false,
        superAdmin: false,
      });
      setNewAdmin({
        adminEmail: "",
        adminDisplayName: "",
        adminPassword: "",
        confirmAdminPassword: "",
        disabled: false,
      });
      setSelectedUser(null);
      setIsEditing(false);
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
    setIsEditing(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const isSaveDisabled =
    !newAdmin.adminDisplayName ||
    !newAdmin.adminEmail ||
    (!isEditing && !newAdmin.adminPassword) ||
    (newAdmin.adminPassword && newAdmin.adminPassword.length < 6) ||
    (newAdmin.adminPassword !== newAdmin.confirmAdminPassword &&
      newAdmin.adminPassword) ||
    (newAdmin.adminPassword && !newAdmin.confirmAdminPassword);

  return (
    <AdminAuth redirectToHome={true}>
      {loading ? (
        <CircularProgress
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <div className="page-container">
          <h1>Manage Admins</h1>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                style={{ marginTop: "10px" }}
              >
                Create Admin
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Display Name</TableCell>
                      <TableCell>Disabled</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.uid}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.displayName}</TableCell>
                        <TableCell>{user.disabled ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleEditUser(user)}
                            endIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => deleteUser(user.uid)}
                            style={{ marginLeft: "10px" }}
                            endIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="create-admin-modal-title"
        aria-describedby="create-admin-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="create-admin-modal-title">
            {isEditing ? "Edit User" : "Create Admin"}
          </h2>
          <TextField
            required
            type="email"
            label="Email"
            name="adminEmail"
            value={newAdmin.adminEmail}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            label="Name"
            name="adminDisplayName"
            value={newAdmin.adminDisplayName}
            onChange={handleInputChange}
            style={{ marginTop: "10px" }}
            fullWidth
          />
          <TextField
            required={!isEditing}
            type="password"
            label="Password"
            name="adminPassword"
            value={newAdmin.adminPassword}
            onChange={handleInputChange}
            style={{ marginTop: "10px" }}
            fullWidth
          />
          <TextField
            required={!isEditing}
            type="password"
            label="Confirm Password"
            name="confirmAdminPassword"
            value={newAdmin.confirmAdminPassword}
            onChange={handleInputChange}
            style={{ marginTop: "10px" }}
            fullWidth
          />

          <div>
            <div>
              <Checkbox
                checked={permissions.manageCatches}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageCatches: e.target.checked,
                  })
                }
                name="manageCatches"
              />
              <label htmlFor="manageCatches">Manage Catches</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.manageCommittee}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageCommittee: e.target.checked,
                  })
                }
                name="manageCommittee"
              />
              <label htmlFor="manageCommittee">Manage Committee</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.manageContacts}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageContacts: e.target.checked,
                  })
                }
                name="manageContacts"
              />
              <label htmlFor="manageContacts">Manage Contacts</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.manageContent}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageContent: e.target.checked,
                  })
                }
                name="manageContent"
              />
              <label htmlFor="manageContent">Manage Content</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.manageMatches}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageMatches: e.target.checked,
                  })
                }
                name="manageMatches"
              />
              <label htmlFor="manageMatches">Manage Matches</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.manageSocials}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageSocials: e.target.checked,
                  })
                }
                name="manageSocials"
              />
              <label htmlFor="manageSocials">Manage Socials</label>
            </div>

            <div>
              <Checkbox
                checked={permissions.manageWaters}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    manageWaters: e.target.checked,
                  })
                }
                name="manageWaters"
              />
              <label htmlFor="manageWaters">Manage Waters</label>
            </div>
            <div>
              <Checkbox
                checked={permissions.superAdmin}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    superAdmin: e.target.checked,
                  })
                }
                name="superAdmin"
              />
              <label htmlFor="superAdmin">Super Admin</label>
            </div>
            {isEditing && (
              <div>
                <Checkbox
                  checked={newAdmin.disabled}
                  onChange={handleInputChange}
                  name="disabled"
                />
                <label htmlFor="disabled">Disabled</label>
              </div>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={isEditing ? updateUser : handleCreateAdmin}
            style={{ marginTop: "10px" }}
            fullWidth
            disabled={isSaveDisabled}
          >
            {isEditing ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
    </AdminAuth>
  );
};

export default ManageAdmins;
