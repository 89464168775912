import React, { useState, useEffect } from "react";
import { Button, Badge, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import AdminAuth from "../AdminAuth/AdminAuth";
import { getUnapprovedSumittions } from "../../services/catchesService";
import { getPermissions } from "../../services/permissionsService";

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [unapproved, setUnapproved] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [authInitialized, setAuthInitialized] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      // Catches Service.
      // const unapprovedData = await getUnapprovedSumittions();
      // setUnapproved(unapprovedData);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthInitialized(!!user);
    });
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    if (authInitialized) {
      fetchData();
      handleSetPermissions();
    }
  }, [authInitialized]);

  const handleSetPermissions = async () => {
    if (auth.currentUser && auth.currentUser.uid) {
      try {
        const userPermissions = await getPermissions(auth.currentUser.uid);
        setPermissions(userPermissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.warn("User is not logged in or uid is not available.");
      setLoading(false);
    }
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  const handleSignOut = async () => {
    signOut(auth)
      .then(() => navigate("/"))
      .catch(console.error);
  };

  const hasPermission = (value) => permissions[value] || permissions.superAdmin;

const renderButton = (label, onClick, value, disabled = false) =>
  value === "signout" || hasPermission(value) ? (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{ marginTop: "10px" }}
      disabled={disabled}
    >
      {label}
    </Button>
  ) : null;

  return (
    <AdminAuth redirectToHome>
      {loading ? (
        <div className="page-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="page-container">
          <h1>
            Admin Dashboard for {auth.currentUser.displayName.split(" ")[0]}
          </h1>

          {renderButton(
            "Manage Admins",
            () => handleNavigation("/admin/manage-admins"),
            "superAdmin"
          )}

          {renderButton(
            "Manage Catches",
            () => handleNavigation("/admin/manage-catches"),
            "manageCatches"
          )}

          {renderButton(
            "Manage Committee",
            () => handleNavigation("/admin/manage-committee"),
            "manageCommittee"
          )}
          {renderButton(
            "Manage Contacts",
            () => handleNavigation("/admin/manage-contacts"),
            "manageContacts"
          )}

          {renderButton(
            "Manage Content",
            () => handleNavigation("/admin/manage-content"),
            "manageContent"
          )}

          {renderButton(
            "Manage Matches",
            () => handleNavigation("/admin/manage-matches"),
            "manageMatches"
          )}

          {renderButton(
            "Manage Socials",
            () => handleNavigation("/admin/manage-socials"),
            "manageSocials"
          )}

          {/* {renderButton(
            "Manage Waters",
            () => handleNavigation("/admin/manage-waters"),

            "manageWaters"
            "manageWaters", true
          )} */}

          {renderButton("Sign Out", handleSignOut, "signout")}
        </div>
      )}
    </AdminAuth>
  );
};

export default Admin;
