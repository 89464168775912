import React, { useState, useEffect } from "react";
import AdminAuth from "../../AdminAuth/AdminAuth";
import {
  getSocials,
  postSocial,
  putSocials,
  deleteSocial,
} from "../../../services/socialsService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageSocials = () => {
  const [socials, setSocials] = useState([]);
  const [selectedSocial, setSelectedSocial] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editedSocial, setEditedSocial] = useState({
    socialName: "",
    socialURL: "",
    socialActive: false,
  });
  const [newSocial, setNewSocial] = useState({
    socialName: "",
    socialURL: "",
    socialActive: false,
  });

  useEffect(() => {
    const currentTitle = document.title;
    document.title = `PAC - Manage Socials`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  const fetchData = async () => {
    try {
      const socialData = await getSocials();
      setSocials(socialData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (social) => {
    setSelectedSocial(social);
    setEditedSocial({
      socialName: social.socialName,
      socialURL: social.socialURL,
      socialActive: social.socialActive,
    });
    setModalOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedSocial(null);
    setNewSocial({
      socialName: "",
      socialURL: "",
      socialActive: false,
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSaveChanges = async () => {
    try {
      if (selectedSocial) {
        await putSocials(selectedSocial.id, editedSocial);
      } else {
        await postSocial(newSocial);
      }
      fetchData();
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteSocial(id);
      fetchData();
    } catch (error) {
      console.error("Error deleting social:", error);
    }
  };

  const isSaveDisabled =
    (selectedSocial && (!editedSocial.socialName || !editedSocial.socialURL)) ||
    (!selectedSocial && (!newSocial.socialName || !newSocial.socialURL));

  return (
    <AdminAuth>
      <div className="page-container">
        <h1>Manage Socials</h1>

        <TableContainer component={Paper}>
          <Button variant="contained" onClick={handleCreateClick}>
            Create
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Network</TableCell>
                <TableCell>Visible</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {socials.map((social, index) => (
                <TableRow key={index}>
                  <TableCell>{social.socialName}</TableCell>
                  <TableCell>{social.socialActive ? "Yes" : "No"}</TableCell>
                  <TableCell>{social.socialURL}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      startIcon={<Edit />}
                      onClick={() => handleEditClick(social)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(social.id)}
                      style={{ marginLeft: "10px" }}
                      endIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              {selectedSocial ? "Edit Social" : "Create Social"}
            </Typography>
            <form>
              <FormControl fullWidth margin="normal">
                <InputLabel id="social-name-label">Social Name</InputLabel>
                <Select
                  labelId="social-name-label"
                  id="social-name"
                  value={
                    selectedSocial
                      ? editedSocial.socialName
                      : newSocial.socialName
                  }
                  onChange={(e) =>
                    selectedSocial
                      ? setEditedSocial({
                          ...editedSocial,
                          socialName: e.target.value,
                        })
                      : setNewSocial({
                          ...newSocial,
                          socialName: e.target.value,
                        })
                  }
                >
                  <MenuItem value="Facebook">Facebook</MenuItem>
                  <MenuItem value="X (Formerly Twitter)">Twitter</MenuItem>
                  <MenuItem value="Instagram">Instagram</MenuItem>
                  <MenuItem value="YouTube">YouTube</MenuItem>
                  <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                  <MenuItem value="Reddit">Reddit</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="URL"
                value={
                  selectedSocial ? editedSocial.socialURL : newSocial.socialURL
                }
                fullWidth
                margin="normal"
                onChange={(e) =>
                  selectedSocial
                    ? setEditedSocial({
                        ...editedSocial,
                        socialURL: e.target.value,
                      })
                    : setNewSocial({ ...newSocial, socialURL: e.target.value })
                }
              />
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Visible</FormLabel>
                <RadioGroup
                  row
                  aria-label="visible"
                  value={
                    selectedSocial
                      ? editedSocial.socialActive.toString()
                      : newSocial.socialActive.toString()
                  }
                  onChange={(e) =>
                    selectedSocial
                      ? setEditedSocial({
                          ...editedSocial,
                          socialActive: e.target.value === "true",
                        })
                      : setNewSocial({
                          ...newSocial,
                          socialActive: e.target.value === "true",
                        })
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </form>
            <Button
              variant="contained"
              onClick={handleSaveChanges}
              disabled={isSaveDisabled}
            >
              Save Changes
            </Button>
          </Box>
        </Modal>
      </div>
    </AdminAuth>
  );
};

export default ManageSocials;
