import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  InputLabel,
  Input,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@mui/material/";
import AdminAuth from "../../AdminAuth/AdminAuth";
import {
  getContent,
  putContent,
  getArticles,
  postArticle,
  deleteArticle,
} from "../../../services/contentService";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageContent = () => {
  const [value, setValue] = useState(0);
  const [content, setContent] = useState([]);
  const [updatedContent, setUpdatedContent] = useState([]);
  const [saving, setSaving] = useState(false);
  const [articles, setArticles] = useState([]);
  const [newArticleModalOpen, setNewArticleModalOpen] = useState(false);
  const [newArticleTitle, setNewArticleTitle] = useState("");
  const [newArticleContent, setNewArticleContent] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUpdatedContent((prevMember) => ({ ...prevMember, photoFile: file }));
  };

  const fetchData = async () => {
    try {
      const contentData = await getContent();
      const articlesData = await getArticles();
      setContent(contentData[0]);
      setUpdatedContent(contentData[0]);
      setArticles(articlesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Content`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTextChange = (event, field) => {
    let updatedValue = event.target.value;

    if (field === "rulesUpdated") {
      const currentDate = new Date();
      updatedValue = formatDate({
        seconds: currentDate.getTime() / 1000,
        nanoseconds: 0,
      });
    }

    const updatedContent = { ...content, [field]: updatedValue };
    setUpdatedContent(updatedContent);
  };

  const handleSave = async (id) => {
    try {
      setSaving(true);

      const formData = new FormData();

      Object.entries(updatedContent).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await putContent(formData, id);

      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.error("Error saving member:", error);
    }
  };


  const formatDate = (timestamp) => {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds || 0;
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleNewArticleModalOpen = () => {
    setNewArticleModalOpen(true);
  };

  const handleNewArticleModalClose = () => {
    setNewArticleModalOpen(false);

    setNewArticleTitle("");
    setNewArticleContent("");
  };

const handleNewArticleSave = async () => {
  try {
    setSaving(true);

    const formData = new FormData();

    formData.append("title", newArticleTitle);
    formData.append("text", newArticleContent);

    await postArticle(formData, content.id);

    setNewArticleModalOpen(false);
    setSaving(false);
  } catch (error) {
    setSaving(false);
    console.error("Error saving new article:", error);
  }
  fetchData()
  setNewArticleTitle("");
  setNewArticleContent("");
};

  const handleDeleteArticle = async (id) => {
    deleteArticle(id);
    fetchData()
  };

  return (
    <AdminAuth>
      <div className="page-container">
        <h1>Manage Content</h1>

        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Home" />
            <Tab label="Rules" />
            <Tab label="Newsletter" />
          </Tabs>

          {/* Home Tab */}
          <TabPanel value={value} index={0}>
            <InputLabel htmlFor="home-text">Home Text</InputLabel>
            <TextField
              id="home-text"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={updatedContent.homeText}
              onChange={(event) => handleTextChange(event, "homeText")}
              inputProps={{ style: { whiteSpace: "pre-line" } }}
            />

            <InputLabel style={{ marginTop: "10px" }} required>
              Picture
            </InputLabel>
            <Input
              type="file"
              id="photoFile"
              accept="image/*"
              onChange={(event) => handleFileChange(event, "photoFile")}
              style={{ marginTop: "10px" }}
            />

            <Button
              onClick={() => handleSave(content.id, "homeText")}
              variant="contained"
              disabled={saving}
              style={{ marginLeft: "10px" }}
            >
              Save
            </Button>

            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                onClick={handleNewArticleModalOpen}
                style={{ marginLeft: "10px" }}
              >
                New Article
              </Button>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Text</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articles.map((article) => (
                    <TableRow key={article.id}>
                      <TableCell>{article.title}</TableCell>
                      <TableCell>{article.text.substring(0, 100)}...</TableCell>
                      <TableCell>{formatDate(article.createdDate)}</TableCell>
                      <TableCell>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditUser(user)}
                          endIcon={<EditIcon />}
                        >
                          Edit
                        </Button> */}
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteArticle(article.id)}
                          style={{ marginLeft: "10px" }}
                          endIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>

          {/* Rules Tab */}
          <TabPanel value={value} index={1}>
            <InputLabel htmlFor="rules-text">Rules Text</InputLabel>
            <TextField
              id="rules-text"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={updatedContent.rulesText}
              onChange={(event) => handleTextChange(event, "rulesText")}
              inputProps={{ style: { whiteSpace: "pre-line" } }}
            />

            <TextField
              label="Rules Updated"
              fullWidth
              value={updatedContent.rulesUpdated}
              onChange={(event) => handleTextChange(event, "rulesUpdated")}
              style={{ marginTop: "10px" }}
            />

            <Button
              onClick={() => handleSave(content.id, "rulesText")}
              variant="contained"
              disabled={saving}
            >
              Save Rules
            </Button>
          </TabPanel>

          {/* Newsletter Tab */}
          <TabPanel value={value} index={2}>
            <InputLabel htmlFor="newsletter-text">Newsletter Text</InputLabel>
            <TextField
              id="newsletter-text"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={updatedContent.newsletterText}
              onChange={(event) => handleTextChange(event, "newsletterText")}
              inputProps={{ style: { whiteSpace: "pre-line" } }}
            />

            <Button
              onClick={() => handleSave(content.id, "newsletterText")}
              variant="contained"
              disabled={saving}
            >
              Save Newsletter
            </Button>
          </TabPanel>
        </Box>

        {/* New Article Modal */}
        <Modal
          open={newArticleModalOpen}
          onClose={handleNewArticleModalClose}
          aria-labelledby="new-article-modal-title"
          aria-describedby="new-article-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              width: 400,
            }}
          >
            <h2 id="new-article-modal-title">New Article</h2>
            <TextField
              id="new-article-title"
              label="Title"
              fullWidth
              value={newArticleTitle}
              onChange={(event) => setNewArticleTitle(event.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id="new-article-content"
              label="Content"
              multiline
              rows={4}
              fullWidth
              value={newArticleContent}
              onChange={(event) => setNewArticleContent(event.target.value)}
              style={{ marginBottom: "20px" }}
            />
            <Button variant="contained" onClick={handleNewArticleSave}>
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleNewArticleModalClose}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      </div>
    </AdminAuth>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default ManageContent;
