import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMember, putMember } from "../../../../services/membersService";
import { TextField, Input, Button, Grid, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminAuth from "../../../AdminAuth/AdminAuth";

const MemberDetails = () => {

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Committee`;

    return () => {
      document.title = currentTitle;
    };
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const [member, setMember] = useState({ id: id });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const memberSnapshot = await getMember(id);
        const memberData = memberSnapshot.data();

        setMember((prevMember) => ({ ...prevMember, ...memberData }));
      } catch (error) {
        console.error("Error fetching member:", error);
      }
    };

    fetchMember();
  }, [id]);

  const handleChange = (field, value) => {
    setMember((prevMember) => ({ ...prevMember, [field]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setMember((prevMember) => ({ ...prevMember, photoFile: file }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);

      const formData = new FormData();

      Object.entries(member).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await putMember(formData, member.id);

      setSaving(false);
      navigate("/admin/manage-committee");
    } catch (error) {
      setSaving(false);
      console.error("Error saving member:", error);
    }
  };

  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Member Details</h1>
        {member ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                value={member.memberName || ""}
                onChange={(e) => handleChange("memberName", e.target.value)}
                fullWidth
              />
              <TextField
                label="Role"
                value={member.memberRole || ""}
                onChange={(e) => handleChange("memberRole", e.target.value)}
                style={{ marginTop: "10px" }}
                fullWidth
              />
              <TextField
                label="Email"
                value={member.contactEmail || ""}
                onChange={(e) => handleChange("contactEmail", e.target.value)}
                style={{ marginTop: "10px" }}
                fullWidth
              />
              <TextField
                label="Number"
                value={member.contactNumber || ""}
                onChange={(e) => handleChange("contactNumber", e.target.value)}
                style={{ marginTop: "10px" }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address (Seperate by commas)"
                value={member.contactAddress || ""}
                multiline
                onChange={(e) => handleChange("contactAddress", e.target.value)}
                // style={{ marginTop: "10px" }}
                fullWidth
              />
              <TextField
                label="Page Position"
                type="number"
                value={member.positionRank || ""}
                onChange={(e) => handleChange("positionRank", e.target.value)}
                style={{ marginTop: "10px" }}
                fullWidth
              />
              <InputLabel style={{ marginTop: "10px" }}>
                User Picture
              </InputLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{ marginTop: "10px" }}
                fullWidth
                disabled={saving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </AdminAuth>
  );
};

export default MemberDetails;
