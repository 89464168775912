import React, { useState, useEffect } from "react";
import { getContacts } from "../../services/contactService";
import CircularProgress from "@mui/material/CircularProgress";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactImage, setContactImage] = useState("");
  const [contacts, setContacts] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const contactData = await getContacts();

      const filteredContactsData = contactData.filter(
        (contact) => contact.id !== "image"
      );
      setContacts(filteredContactsData);
      

      const image = contactData.find(
        (contact) => contact.id === "image"
      );
      if (image) {
        setContactImage(image.photoURL);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;
    document.title = `${currentTitle} - Contact Us`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  return (
    <div className="page-container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <h1>Contact Us</h1>
          <img
            src={contactImage}
            style={{ maxWidth: "80%", maxHeight: "80%", marginBottom: "10px" }}
            alt="Home Image"
          />
        </>
      )}
      <div className="members">
        {contacts.map((row) => (
          <div className="member" key={row.id}>

            <div className="memberRole">{row.title}</div>
            <div className="memberName">{row.name}</div>
            <div className="memberEmail">{row.phoneNumber}</div>
            <div className="memberPhone">{row.email}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
