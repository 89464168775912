import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  deleteObject as deleteStorageObject,
} from "firebase/storage";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getMembers = async () => {
  const querySnapshot = await getDocs(collection(db, "members"));
  const membersData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return membersData;
};

export { getMembers };

const getMember = async (id) => {
  const docRef = doc(db, "members", id);
  const docSnap = await getDoc(docRef);

  return docSnap;
};

export { getMember };

const postMember = async (formData) => {
  try {
    const memberData = {};
    const storage = getStorage();
    const photoFile = formData.get("photoFile");

    formData.forEach((value, key) => {
      if (key !== "photoFile") {
        memberData[key] = value;
      }
    });

    const docRef = await addDoc(collection(db, "members"), memberData);

    const storageRef = ref(storage, "memberDisplayPhotos/" + docRef.id);

    await uploadBytes(storageRef, photoFile);

    const downloadURL = await getDownloadURL(storageRef);

    await updateDoc(doc(db, "members", docRef.id), {
      photoURL: downloadURL,
    });
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export { postMember };

const putMember = async (formData, memberId) => {
  try {
    const memberData = {};
    const storage = getStorage();
    const photoFile = formData.get("photoFile");

    formData.forEach((value, key) => {
      if (key !== "photoFile") {
        memberData[key] = value;
      }
    });

    await updateDoc(doc(db, "members", memberId), memberData);

    if (photoFile) {
      const existingMemberDoc = await getDoc(doc(db, "members", memberId));
      const existingPhotoURL = existingMemberDoc.data().photoURL;

      if (existingPhotoURL) {
        const existingPhotoRef = ref(storage, existingPhotoURL);

        await deleteObject(existingPhotoRef);
      }

      const storageRef = ref(storage, "memberDisplayPhotos/" + memberId);
      await uploadBytes(storageRef, photoFile);

      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(db, "members", memberId), {
        photoURL: downloadURL,
      });
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

export { putMember };

const deleteMember = async (memberId) => {
  try {
    const memberDocRef = doc(db, "members", memberId);
    const memberDoc = await getDoc(memberDocRef);

    if (memberDoc.exists()) {
      await deleteDoc(memberDocRef);

      const photoURL = memberDoc.data().photoURL;

      if (photoURL) {
        const storage = getStorage();
        const photoRef = ref(storage, photoURL);

        await deleteStorageObject(photoRef);
      }
    } else {
      console.log("Member not found.");
    }
  } catch (error) {
    console.error("Error deleting member: ", error);
    throw error;
  }
};

export { deleteMember };
