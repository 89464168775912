import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getMatchRules = async () => {
  const querySnapshot = await getDocs(collection(db, "matchRules"));
  const matcheRulesData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return matcheRulesData;
};

export { getMatchRules };

const postMatchRule = async (ruleDetails) => {
  try {
    await addDoc(collection(db, "matchRules"), {
      rank: ruleDetails.rank,
      value: ruleDetails.value,
    });
  } catch (error) {
    console.error("Error adding rule: ", error);
    throw error;
  }
};

export { postMatchRule };

const putMatchRule = async (ruleDetails, id) => {
  try {
    await updateDoc(doc(db, "matchRules", id), ruleDetails);
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

export { putMatchRule };
