import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getPermissions = async (uid) => {
  try {
    const docRef = doc(db, "userPermissions", uid);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting permissions:", error);
    throw error;
  }
};

export { getPermissions };

const postPermissionsUser = async (uid, permissions) => {
  try {
    const userPermissionsRef = doc(db, "userPermissions", uid);
    await setDoc(userPermissionsRef, permissions);
  } catch (error) {
    console.error("Error adding permissions: ", error);
    throw error;
  }
};

export { postPermissionsUser };

const putPermissionsUser = async (uid, permissions) => {
  try {
    const permissionsRef = doc(db, "userPermissions", uid);

    await updateDoc(permissionsRef, permissions);
  } catch (error) {
    console.error("Error updating user: ", error);
    throw error;
  }
};

export { putPermissionsUser };

const deletePermissionsUser = async (uid) => {
  try {
    const userPermissionsRef = doc(db, "userPermissions", uid);
    const userPermissionsDoc = await getDoc(userPermissionsRef);

    if (userPermissionsDoc.exists()) {
      await deleteDoc(userPermissionsRef);
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error deleting User: ", error);
    throw error;
  }
};

export { deletePermissionsUser };
