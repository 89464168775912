import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AdminAuth = ({ children, redirectToHome = true }) => {
  const auth = getAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [redirected, setRedirected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);

      if (!user && redirectToHome && !redirected) {
        navigate("/");
        setRedirected(true);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate, redirected, redirectToHome]);

  return isAuthenticated ? children : null;
};

export default AdminAuth;
