import {
  getFirestore,
  collection,
  getDocs,
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getMatches = async () => {
  const querySnapshot = await getDocs(collection(db, "matches"));
  const matchesData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return matchesData;
};

export { getMatches };

const deleteMatch = async (matchId) => {
  try {
    const matchDocRef = doc(db, "matches", matchId);
    const matchDoc = await getDoc(matchDocRef);

    if (matchDoc.exists()) {
      await deleteDoc(matchDocRef);
    } else {
      console.log("Match not found.");
    }
  } catch (error) {
    console.error("Error deleting match: ", error);
    throw error;
  }
};

export { deleteMatch };

const postMatch = async (matchDetails) => {
  try {
    const dateObject = new Date(matchDetails.date);
    const timestamp = Timestamp.fromDate(dateObject);

    await addDoc(collection(db, "matches"), {
      description: matchDetails.description,
      location: matchDetails.location,
      drawTime: matchDetails.drawTime,
      date: timestamp,
    });
  } catch (error) {
    console.error("Error adding match: ", error);
    throw error;
  }
};

export { postMatch };
