import { useEffect } from "react";
import React from "react";

const Contact = () => {

    useEffect(() => {
      const currentTitle = document.title;

      document.title = `${currentTitle} - Waters`;

      return () => {
        document.title = currentTitle;
      };
    }, []);
  return <div className="page-container">Waters Page Content</div>;
};

export default Contact;
