import React, { useState, useEffect } from "react";
import { getMatches } from "../../services/matchesService";
import { getMatchRules } from "../../services/rulesService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
} from "@mui/material";

const Matches = () => {
  const [matches, setMatches] = useState([]);
  const [matchRules, setMatchRules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hideOldMatches, setHideOldMatches] = useState(false);

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Matches`;

    return () => {
      document.title = currentTitle;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const matchesData = await getMatches();
        const matchRulesData = await getMatchRules();
        setMatches(matchesData);
        setMatchRules(matchRulesData);
      } catch (error) {
        console.error("Error fetching members:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds || 0;
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const currentDate = new Date();
  const currentDatePlusOne = new Date(currentDate);
  currentDatePlusOne.setDate(currentDatePlusOne.getDate() - 1);

  const currentDateTimestamp = Math.floor(currentDatePlusOne.getTime() / 1000);

  const filteredMatches = hideOldMatches
    ? matches.filter((match) => {
        const matchDateTimestamp = match.date.seconds;
        return matchDateTimestamp >= currentDateTimestamp;
      })
    : matches;

  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "left",
        maxWidth: "800px", // Set a maximum width as per your preference
        margin: "auto", // Center the container horizontally
      }}
    >
      <h1 style={{ marginBottom: "20px", fontSize: "1.5rem" }}>
        Priory Angling Club Match List
      </h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <div>
              <Checkbox
                checked={hideOldMatches}
                onChange={() => setHideOldMatches(!hideOldMatches)}
                color="primary"
                inputProps={{ "aria-label": "hide old matches checkbox" }}
              />
              <label>Hide Past Matches</label>
            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Location
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Description
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Draw Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMatches
                  .sort((a, b) => a.date - b.date)
                  .map((match) => (
                    <TableRow key={match.id}>
                      <TableCell style={{ fontSize: "0.9rem" }}>
                        {formatDate(match.date)}
                      </TableCell>
                      <TableCell style={{ fontSize: "0.9rem" }}>
                        {match.location}
                      </TableCell>
                      <TableCell style={{ fontSize: "0.9rem" }}>
                        {match.description}
                      </TableCell>
                      <TableCell style={{ fontSize: "0.9rem" }}>
                        {match.drawTime}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className="rules"
            style={{
              marginTop: "10px",
              textAlign: "left",
              background: "none",
              alignItems: "flex-start",
            }}
          >
            Priory Angling Club Match Rules:
            <div
              style={{
                fontWeight: "normal",
                marginTop: "5px",
                paddingLeft: "20px",
              }}
            >
              {matchRules
                .sort((a, b) => a.rank - b.rank)
                .map((rule) => (
                  <li
                    key={rule.id}
                    style={{
                      marginTop: "5px",
                      listStyleType: "none",
                      display: "block",
                    }}
                  >
                    {rule.rank}) {rule.value}
                  </li>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Matches;
