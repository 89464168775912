import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { getContent } from "../../services/contentService";
import React from "react";

const NewsLetter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterText, setNewsletterText] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const contentData = await getContent();
      setNewsletterText(contentData[0].newsletterText);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Newsletter`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  // TODO:
  // Add a heading for article.
  // Make Font 1 size biggerds

  return (
    <div className="page-container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <h1>Latest Club Newsletter</h1>
          <div
            style={{
              whiteSpace: "pre-line",
              maxWidth: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{
              __html: newsletterText.replace(/\\n/g, "\n"),
            }}
          />
        </>
      )}
    </div>
  );
};

export default NewsLetter;
