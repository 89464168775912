import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AdminAuth from "../../AdminAuth/AdminAuth";

const ManageWaters = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Content`;
    // fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AdminAuth>
      <div className="page-container">
        <h1>Manage Content</h1>

        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Home" />
            <Tab label="Rules" />
            <Tab label="Newsletter" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Typography>Tab 1 Content</Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography>Tab 2 Content</Typography>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography>Tab 3 Content</Typography>
          </TabPanel>
        </Box>
      </div>
    </AdminAuth>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default ManageWaters;
