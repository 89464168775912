import React, { useState, useEffect } from "react";
import { getMembers, deleteMember } from "../../../services/membersService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import AdminAuth from "../../AdminAuth/AdminAuth";
import ConfirmDeleteModal from "../../ConfrimDeleteModal/ConfirmDeleteModal";

const ManageCommittee = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Committee`;

    return () => {
      document.title = currentTitle;
    };
  }, []);
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const membersData = await getMembers();
      setMembers(membersData);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/admin/manage-committee/${id}`);
  };

  const handleDelete = async (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (itemToDelete) {
        await deleteMember(itemToDelete);
        fetchData();
        setItemToDelete(null);
      }
    } catch (error) {
      console.error("Error handling delete operation: ", error);
    }
    setIsDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
    setIsDialogOpen(false);
  };

  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Manage Committee Members</h1>
        <TableContainer component={Paper}>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => {
              navigate("/admin/manage-committee/create");
            }}
          >
            Add Committee Member
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Page #</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members
                .sort((a, b) => a.positionRank - b.positionRank)
                .map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.positionRank}</TableCell>
                    <TableCell>{member.memberName}</TableCell>
                    <TableCell>{member.memberRole}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(member.id)}
                        endIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(member.id)}
                        style={{ marginLeft: "10px" }}
                        endIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ConfirmDeleteModal
          open={isDialogOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this item?"
        />
      </div>
    </AdminAuth>
  );
};

export default ManageCommittee;
