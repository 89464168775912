import React, { useState, useEffect } from "react";
import { getMembers } from "../../services/membersService";
import "./OurPeople.css";
import { CircularProgress } from "@mui/material";

const OurPeople = () => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Our People`;

    return () => {
      document.title = currentTitle;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const membersData = await getMembers();
        const sortedMembers = membersData.sort(
          (a, b) => a.positionRank - b.positionRank
        );
        setMembers(sortedMembers);
      } catch (error) {
        console.error("Error fetching members:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="page-container">
      <h1>The Committee</h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="members">
          {members.map((member) => (
            <div className="member" key={member.id}>
              <div className="memberPhoto">
                <img
                  src={
                    member.photoURL
                      ? member.photoURL
                      : `https://ui-avatars.com/api/?name=${member.memberName}&size=350x250`
                  }
                  alt="user avatar"
                  id="avatar-preview"
                  style={{ maxWidth: "350px", maxHeight: "250px" }}
                />
              </div>
              <div className="memberRole">{member.memberRole}</div>
              <div className="memberName">{member.memberName}</div>
              {member.contactAddress ? (
                <div className="memberAddress">
                  {member.contactAddress
                    .split(",")
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line.trim()}
                        {index < array.length - 1 && ","}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              ) : (
                ""
              )}
              <div className="memberEmail">{member.contactEmail}</div>
              <div className="memberPhone">{member.contactNumber}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OurPeople;
