import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getSocials = async () => {
  const querySnapshot = await getDocs(collection(db, "externalNav"));
  const socialData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return socialData;
};

export { getSocials };

const postSocial = async (newSocial) => {
  try {
    await addDoc(collection(db, "externalNav"), {
      socialActive: newSocial.socialActive,
      socialName: newSocial.socialName,
      socialURL: newSocial.socialURL,
    });
  } catch (error) {
    console.error("Error adding permissions: ", error);
    throw error;
  }
};

export { postSocial };

const putSocials = async (id, selectedSocial) => {
  try {
    const socialsRef = doc(db, "externalNav", id);

    await updateDoc(socialsRef, {
      socialActive: selectedSocial.socialActive,
      socialName: selectedSocial.socialName,
      socialURL: selectedSocial.socialURL,
    });
  } catch (error) {
    console.error("Error updating user: ", error);
    throw error;
  }
};

export { putSocials };

const deleteSocial = async (id) => {
  try {
    const socialDocRef = doc(db, "externalNav", id);
    const socialDoc = await getDoc(socialDocRef);

    if (socialDoc.exists()) {
      await deleteDoc(socialDocRef);
    } else {
      console.log("Match not found.");
    }
  } catch (error) {
    console.error("Error deleting match: ", error);
    throw error;
  }
};

export { deleteSocial };
