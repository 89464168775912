import {
  Grid,
  TextField,
  Button,
  Toolbar,
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Login = () => {
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/admin");
      }
    });
    const currentTitle = document.title;

    document.title = `PAC - Admin Login`;

    return () => {
      document.title = currentTitle;
      unsubscribe();
    };
  }, []);

  const navigate = useNavigate();
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [user, setUser] = useState([]);
  const [loginError, setLoginError] = useState(null);

  const handleEmailChange = (event) => {
    setAdminEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setAdminPassword(event.target.value);
  };

  const handleLogin = () => {
    setLoginError(null)
    const auth = getAuth();

    signInWithEmailAndPassword(auth, adminEmail, adminPassword)
      .then((userCredential) => {
        setUser(userCredential.user);

        if (user) {
          navigate("/admin");
        }
      })
      .catch((error) => {
        if (error.message === "Firebase: Error (auth/invalid-credential).") {
          setLoginError("Incorrect Password");
        } else if (error.message === "Firebase: Error (auth/missing-password).") {
          setLoginError("Enter Password");
        } else if (error.message === "Firebase: Error (auth/invalid-email).") {
          setLoginError("Incorrect Email");
        } else {
          setLoginError(error.message);
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      <div className="page-container">
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={adminEmail}
                id="adminLoginEmail"
                size="normal"
                type="email"
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                value={adminPassword}
                id="adminLoginPassword"
                size="normal"
                type="password"
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleLogin} variant="contained">
                Login
              </Button>
            </Grid>
          </Grid>
          {loginError && (
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography color="error">
                <b>{loginError}</b>
              </Typography>
            </Grid>
          )}
        </Box>
      </div>
    </>
  );
};

export default Login;
