import React, { useState, useEffect } from "react";
import AdminAuth from "../../AdminAuth/AdminAuth";
import {
  postImage,
  getContacts,
  postContact,
  deleteContact,
} from "../../../services/contactService";
import { getMembers } from "../../../services/membersService";
import {
  Modal,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  TableContainer,
  Paper,
} from "@mui/material";

const ManageContact = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openChangeImageModal, setOpenChangeImageModal] = useState(false);
  const [formData, setFormData] = useState({
    photoFile: null,
    title: "",
    selectedMember: "",
  });
  const [members, setMembers] = useState([]);
  const [contacts, setContacts] = useState([]);

  const fetchData = async () => {
    try {
      const membersData = await getMembers();
      const contactsData = await getContacts();
      const filteredContactsData = contactsData.filter(
        (contact) => contact.id !== "image"
      );
      setContacts(filteredContactsData);
      setMembers(membersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const currentTitle = document.title;
    document.title = `PAC - Manage Contacts`;
    return () => {
      document.title = currentTitle;
    };
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const selectedMemberObject = members.find(
      (member) => member.memberName === value
    );

    setFormData({
      ...formData,
      [name]: value,
      selectedMemberObject: selectedMemberObject,
    });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, photoFile: e.target.files[0] });
  };

  const handleSubmitContact = async (e) => {
    e.preventDefault();
    try {
      const { title, selectedMember } = formData;
      const selectedMemberObject = members.find(
        (member) => member.memberName === selectedMember
      );

      if (!selectedMemberObject) {
        console.error("Selected member not found");
        return;
      }

      const contactData = {
        title,
        name: selectedMemberObject.memberName,
        phoneNumber: selectedMemberObject.contactNumber,
        email: selectedMemberObject.contactEmail,
      };

      await postContact(contactData);
      handleCloseAddModal();

      setFormData({
        photoFile: null,
        title: "",
        selectedMember: "",
      });

      fetchData();
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  const handleSubmitImage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("photoFile", e.target.elements.photoFile.files[0]);
      await postImage(formData);
      handleCloseChangeImageModal();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteContact(id);
      fetchData();
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenChangeImageModal = () => {
    setOpenChangeImageModal(true);
  };

  const handleCloseChangeImageModal = () => {
    setOpenChangeImageModal(false);
  };

  // const filteredMembers = members.filter((member) => {
  //   // Check if the member is already a contact
  //   return !contacts.some((contact) => contact.name === member.memberName);
  // });

  return (
    <AdminAuth>
      <div className="page-container">
        <h1>Manage Contact</h1>

        {/* Add Contact Modal */}
        <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
          aria-labelledby="manage-contact-modal"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "20px",
            }}
          >
            <h2 id="manage-contact-modal">Add Contact</h2>
            <form onSubmit={handleSubmitContact}>
              <TextField
                select
                label="Select Member"
                name="selectedMember"
                value={formData.selectedMember}
                onChange={handleInputChange}
                fullWidth
                required
              >
                {members.map((member) => (
                  <MenuItem key={member.id} value={member.memberName}>
                    {member.memberName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <Button variant="contained" type="submit">
                Submit Contact
              </Button>
            </form>
          </div>
        </Modal>

        {/* Change Image Modal */}
        <Modal
          open={openChangeImageModal}
          onClose={handleCloseChangeImageModal}
          aria-labelledby="change-image-modal"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "20px",
            }}
          >
            <h2 id="change-image-modal">Change Image</h2>
            <form onSubmit={handleSubmitImage}>
              <input
                type="file"
                name="photoFile"
                onChange={handleImageChange}
                accept="image/*"
                required
              />
              <Button variant="contained" type="submit">
                Submit Image
              </Button>
            </form>
          </div>
        </Modal>

        {/* MUI Table */}
        <TableContainer component={Paper}>
          <Button
            variant="contained"
            onClick={handleOpenAddModal}
            style={{ marginRight: "10px" }}
          >
            Add Contact
          </Button>
          <Button variant="contained" onClick={handleOpenChangeImageModal}>
            Change Image
          </Button>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </AdminAuth>
  );
};

export default ManageContact;
