import React, { useState } from "react";
import { postMember } from "../../../../services/membersService";
import { TextField, Button, Grid, Input, InputLabel } from "@mui/material";
import AdminAuth from "../../../AdminAuth/AdminAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CreateMember = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Committee`;

    return () => {
      document.title = currentTitle;
    };
  }, []);
  const [newMember, setNewMember] = useState({
    memberName: "",
    contactEmail: "",
    contactAddress: "",
    contactNumber: "",
    memberRole: "",
    positionRank: "",
    photoFile: null,
  });
  const [saving, setSaving] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember((prevMember) => ({ ...prevMember, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewMember((prevMember) => ({ ...prevMember, photoFile: file }));
  };

  const handleCreateMember = async () => {
    try {
      setSaving(true);
      const formData = new FormData();
      Object.entries(newMember).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await postMember(formData);

      setNewMember({
        memberName: "",
        contactEmail: "",
        contactAddress: "",
        contactNumber: "",
        memberRole: "",
        positionRank: "",
        photoFile: null,
      });
      setSaving(false);
      navigate("/admin/manage-committee");
    } catch (error) {
      setSaving(false);
      console.error("Error creating new member:", error);
    }
  };

  const isCreateDisabled =
    !newMember.memberName ||
    !newMember.memberRole ||
    !newMember.positionRank ||
    !newMember.photoFile;

  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Create Committee Member</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Name"
              name="memberName"
              value={newMember.memberName}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              required
              label="Role"
              name="memberRole"
              value={newMember.memberRole}
              onChange={handleInputChange}
              style={{ marginTop: "10px" }}
              fullWidth
            />
            <TextField
              label="Email"
              name="contactEmail"
              value={newMember.contactEmail}
              onChange={handleInputChange}
              style={{ marginTop: "10px" }}
              fullWidth
            />
            <TextField
              label="Number"
              name="contactNumber"
              type="number"
              value={newMember.contactNumber}
              onChange={handleInputChange}
              style={{ marginTop: "10px" }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Address (Seperate by commas)"
              name="contactAddress"
              multiline
              value={newMember.contactAddress}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              required
              label="Page Position"
              name="positionRank"
              type="number"
              value={newMember.positionRank}
              onChange={handleInputChange}
              style={{ marginTop: "10px" }}
              fullWidth
            />
            <InputLabel style={{ marginTop: "10px" }} required>
              User Picture
            </InputLabel>
            <Input
              type="file"
              name="photoFile"
              accept="image/*"
              onChange={handleFileChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateMember}
              style={{ marginTop: "10px" }}
              fullWidth
              disabled={isCreateDisabled || saving}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </div>
    </AdminAuth>
  );
};

export default CreateMember;
