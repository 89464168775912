import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getAllCatches,
  approveCatch,
  deleteCatch,
} from "../../../services/catchesService";
import AdminAuth from "../../AdminAuth/AdminAuth";
import ConfirmDeleteModal from "../../ConfrimDeleteModal/ConfirmDeleteModal";

const ImageViewer = ({ memberName, imgUrl, onClose }) => (
  <Dialog open={true} onClose={onClose}>
    <DialogTitle>{`${memberName}'s Submission`}</DialogTitle>
    <DialogContent>
      <img src={imgUrl} alt="Preview" style={{ maxWidth: "100%" }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const ManageCatches = () => {
  const [showPublic, setShowPublic] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [catches, setCatches] = useState([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [currentMemberName, setCurrentMemberName] = useState("");

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Catches`;

    return () => {
      document.title = currentTitle;
    };
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const catchesData = await getAllCatches();
      setCatches(catchesData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickApprove = async (id) => {
    try {
      await approveCatch(id);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  const filteredCatches = showPublic
    ? catches.filter((row) => row.isPublic === true)
    : catches.filter((row) => row.isPublic === false);

  const handleClickDelete = async (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (itemToDelete) {
        console.log(itemToDelete);
        await deleteCatch(itemToDelete);
        fetchData();
        setItemToDelete(null);
      }
    } catch (error) {
      console.error("Error handling delete operation: ", error);
    }
    setIsDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
    setIsDialogOpen(false);
  };

  const openImageViewer = (memberName, imgURL) => {
    setCurrentMemberName(memberName);
    setCurrentImageUrl(imgURL);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentMemberName("");
    setCurrentImageUrl("");
    setIsImageViewerOpen(false);
  };

  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Manage Catches</h1>
        {isLoading ? (
          <CircularProgress style={{ margin: "20px" }} />
        ) : (
          <TableContainer component={Paper}>
            <div>
              <Checkbox
                checked={showPublic}
                onChange={() => setShowPublic(!showPublic)}
                color="primary"
                inputProps={{ "aria-label": "show public" }}
              />
              <label>{showPublic ? "Hide Unapproved" : "Show Approved"}</label>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Fish</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Public</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCatches.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.memberName}</TableCell>
                    <TableCell>{row.catchLocation}</TableCell>
                    <TableCell>{row.fishType}</TableCell>
                    <TableCell>
                      {row.fishLbs}
                      {row.fishLbs ? "lbs " : ""}
                      {row.fishOz}
                      {row.fishOz ? "oz" : ""}
                      {row.fishLbs || row.fishOz ? "" : "Not Provided"}
                    </TableCell>
                    <TableCell>{row.isPublic ? "Y" : "N"}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<LaunchIcon />}
                        onClick={() =>
                          openImageViewer(row.memberName, row.imgURL)
                        }
                      >
                        View Image
                      </Button>
                    </TableCell>
                    <TableCell>
                      {row.isPublic === false && (
                        <Button
                          variant="contained"
                          endIcon={<DoneIcon />}
                          color="success"
                          onClick={() => handleClickApprove(row.id)}
                        >
                          Approve
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        endIcon={<ClearIcon />}
                        color="error"
                        style={{
                          marginLeft: row.isPublic === false ? "10px" : "0",
                        }}
                        onClick={() => handleClickDelete(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {isImageViewerOpen && (
          <ImageViewer
            memberName={currentMemberName}
            imgUrl={currentImageUrl}
            onClose={closeImageViewer}
          />
        )}

        <ConfirmDeleteModal
          open={isDialogOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this item?"
        />
      </div>
    </AdminAuth>
  );
};

export default ManageCatches;
