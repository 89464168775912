import React, { useState, useEffect } from "react";
import {
  deleteMatch,
  getMatches,
  postMatch,
} from "../../../services/matchesService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  ButtonGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AdminAuth from "../../AdminAuth/AdminAuth";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteModal from "../../ConfrimDeleteModal/ConfirmDeleteModal";

const ManageMatches = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `PAC - Manage Matches`;

    return () => {
      document.title = currentTitle;
    };
  }, []);

  const [matches, setMatches] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newMatchDetails, setNewMatchDetails] = useState({
    description: "",
    location: "",
    drawTime: "",
    date: null,
  });

  const currentDate = new Date();

  const fetchData = async () => {
    try {
      const matchesData = await getMatches();
      setMatches(matchesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds || 0;
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setNewMatchDetails({
      description: "",
      location: "",
      drawTime: "",
      date: null,
    });
    setModalOpen(false);
  };

  const handleInputChange = (field, value) => {
    const formattedValue = value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    setNewMatchDetails((prevDetails) => ({
      ...prevDetails,
      [field]: formattedValue,
    }));
  };

  const handleAddMatch = async () => {
    try {
      await postMatch(newMatchDetails);

      setNewMatchDetails({
        description: "",
        location: "",
        drawTime: "",
        date: new Date().toISOString().split("T")[0],
      });

      fetchData();
    } catch (error) {
      console.error("Error adding match:", error);
    }

    handleCloseModal();
  };

  const handleEditRules = () => {
    navigate("/admin/manage-matches/match-rules");
  };

  const isCreateDisabled =
    !newMatchDetails.description ||
    !newMatchDetails.location ||
    !newMatchDetails.drawTime ||
    !newMatchDetails.date;

  const handleDelete = async (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (itemToDelete) {
        console.log(itemToDelete);
        await deleteMatch(itemToDelete);
        fetchData();
        setItemToDelete(null);
      }
    } catch (error) {
      console.error("Error handling delete operation: ", error);
    }
    setIsDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
    setIsDialogOpen(false);
  };
  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Manage Matches</h1>
        <TableContainer component={Paper}>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Match
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleEditRules}
            style={{ marginLeft: "10px" }}
            endIcon={<EditIcon />}
          >
            Edit Rules
          </Button>

          <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle>Add Match</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    fullWidth
                    value={newMatchDetails.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    style={{ marginTop: "5px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Location"
                    fullWidth
                    value={newMatchDetails.location}
                    onChange={(e) =>
                      handleInputChange("location", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Draw Time"
                    fullWidth
                    value={newMatchDetails.drawTime}
                    onChange={(e) =>
                      handleInputChange("drawTime", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Date"
                    type="date"
                    fullWidth
                    value={newMatchDetails.date || ""}
                    onChange={(e) => handleInputChange("date", e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ButtonGroup style={{ float: "right" }}>
                    <Button
                      onClick={handleCloseModal}
                      variant="contained"
                      color="error"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleAddMatch}
                      style={{ marginLeft: "10px" }}
                      disabled={isCreateDisabled}
                    >
                      Add Match
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Draw Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches
                .sort((a, b) => a.date - b.date)
                .map((match) => (
                  <TableRow key={match.id}>
                    <TableCell>
                      {match.date.toDate() < currentDate ? (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {formatDate(match.date)} (past)
                        </span>
                      ) : (
                        formatDate(match.date)
                      )}
                    </TableCell>
                    <TableCell>{match.location}</TableCell>
                    <TableCell>{match.description}</TableCell>
                    <TableCell>{match.drawTime}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(match.id)}
                        style={{ marginLeft: "10px" }}
                        endIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ConfirmDeleteModal
          open={isDialogOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this item?"
        />
      </div>
    </AdminAuth>
  );
};

export default ManageMatches;
