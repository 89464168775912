import React, { useState, useEffect } from "react";
import {
  getMatchRules,
  postMatchRule,
  putMatchRule,
} from "../../../../services/rulesService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  ButtonGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AdminAuth from "../../../AdminAuth/AdminAuth";

const EditRules = () => {
  const [rules, setRules] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newRule, setNewRule] = useState({
    rank: "",
    value: "",
  });
  const [highestRank, setHighestRank] = useState(0);
  const [isEditRule, setIsEditRule] = useState(false);
  const [editingRuleId, setEditingRuleId] = useState(null);

  const fetchData = async () => {
    try {
      const rulesData = await getMatchRules();

      const maxRank = Math.max(...rulesData.map((rule) => rule.rank));

      if (maxRank > highestRank) {
        setHighestRank(maxRank);
      }

      setRules(rulesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenModal = (id) => {
    setModalOpen(true);
    setIsEditRule(true);
    setEditingRuleId(id);
    const ruleToEdit = rules.find((rule) => rule.id === id);
    if (ruleToEdit) {
      setNewRule({
        rank: ruleToEdit.rank,
        value: ruleToEdit.value,
      });
    }
  };

  const handleCloseModal = () => {
    setNewRule({
      rank: "",
      value: "",
    });
    setModalOpen(false);
  };

  const handleInputChange = (field, value) => {
    setNewRule((prevDetails) => ({
      ...prevDetails,
      [field]: value,
      rank: isEditRule ? prevDetails.rank : highestRank + 1,
    }));

    if (isEditRule) {
      setRules((prevRules) =>
        prevRules.map((rule) =>
          rule.id === editingRuleId ? { ...rule, [field]: value } : rule
        )
      );
    }
  };

  const handleSaveRule = async () => {
    try {
      if (isEditRule) {
         await putMatchRule(newRule, editingRuleId);
      } else {
        await postMatchRule(newRule);
      }

      setNewRule({
        rank: "",
        value: "",
      });

      fetchData();
    } catch (error) {
      console.error("Error adding/editing match rule:", error);
    }

    handleCloseModal();
  };

  return (
    <AdminAuth redirectToHome={true}>
      <div className="page-container">
        <h1>Manage Match Rules</h1>
        <TableContainer component={Paper}>
          <Button
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              setModalOpen(true);
              setIsEditRule(false);
            }}
          >
            Add New Rule
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Rule</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules
                .sort((a, b) => a.rank - b.rank)
                .map((rule) => (
                  <TableRow key={rule.id}>
                    <TableCell>{rule.rank}</TableCell>
                    <TableCell>{rule.value}</TableCell>
                    <TableCell>
                      <Button
                        endIcon={<EditIcon />}
                        variant="contained"
                        onClick={() => handleOpenModal(rule.id)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle>{isEditRule ? "Edit Rule" : "Add Rule"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Rule"
                  fullWidth
                  value={isEditRule ? newRule.value : newRule.value}
                  onChange={(e) => handleInputChange("value", e.target.value)}
                  style={{ marginTop: "5px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup style={{ float: "right" }}>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSaveRule}
                    style={{ marginLeft: "10px" }}
                    disabled={!newRule.value}
                  >
                    {isEditRule ? "Save Rule" : "Add Rule"}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </AdminAuth>
  );
};

export default EditRules;
