import React, { useState, useEffect } from "react";
import { getWatersContent } from "../../../services/watersService";

const Weston = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Weston`;

    const fetchData = async () => {
      try {
        const pageData = await getWatersContent("weston");
        const sortedData = pageData.sort(
          (a, b) => a.pagePosition - b.pagePosition
        );
        setData(sortedData);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchData();

    return () => {
      document.title = currentTitle;
    };
  }, []);

  return (
    <div className="page-container">
      <>
        <h1 style={{ textAlign: "center" }}>Weston</h1>
        {data.map((item) => (
          <div style={{ textAlign: "center" }} key={item.id}>
            <img
              src={item.imgUrl}
              style={{
                maxWidth: "80%",
                maxHeight: "80%",
                marginBottom: "10px",
              }}
              alt="WestonImage"
            />
            <p>{item.text}</p>
          </div>
        ))}
      </>
    </div>
  );
};

export default Weston;
