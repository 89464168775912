import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  Timestamp,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  deleteObject as deleteStorageObject,
} from "firebase/storage";

import app from "../firebaseConfig";

const db = getFirestore(app);

const getContent = async () => {
  const querySnapshot = await getDocs(collection(db, "content"));
  const contentData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return contentData;
};

export { getContent };

const getArticles = async () => {
  const contentRef = collection(db, "content");
  const querySnapshot = await getDocs(contentRef);

  const docRef = querySnapshot.docs[0].ref;

  const homeArticlesRef = collection(docRef, "homeArticles");
  const homeArticlesSnapshot = await getDocs(homeArticlesRef);

  const articlesData = homeArticlesSnapshot.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .sort((a, b) => b.createdDate - a.createdDate);

  return articlesData.slice(0, 3);
};

export { getArticles };

const putArticle = async (formData, articleId) => {
  try {
    const articleData = {};
    formData.forEach((value, key) => {
      articleData[key] = value;
    });
    articleData.createdDate = Timestamp.now();

    await setDoc(
      doc(db, "content", "t2dWUwP906vNTOm5NkRM", "homeArticles", articleId),
      articleData
    );
  } catch (error) {
    console.error("Error updating article document: ", error);
    throw error;
  }
};

export { putArticle };

const postArticle = async (formData) => {
  try {
    const articleData = {};
    formData.forEach((value, key) => {
      articleData[key] = value;
    });
    articleData.createdDate = Timestamp.now();

    await addDoc(
      collection(db, "content", "t2dWUwP906vNTOm5NkRM", "homeArticles"),
      articleData
    );
  } catch (error) {
    console.error("Error posting article document: ", error);
    throw error;
  }
};

export { postArticle };

const deleteArticle = async (articleId) => {
  try {
    const articleDocRef = doc(db, "content", "t2dWUwP906vNTOm5NkRM", "homeArticles", articleId);
    const articleDoc = await getDoc(articleDocRef);

    if (articleDoc.exists()) {
      await deleteDoc(articleDocRef);
    } else {
      console.log("Article not found.");
    }
  } catch (error) {
    console.error("Error deleting Article: ", error);
    throw error;
  }
};

export { deleteArticle };


const putContent = async (formData, id) => {
  try {
    const contentData = {};
    const storage = getStorage();
    const photoFile = formData.get("photoFile");

    formData.forEach((value, key) => {
      if (key !== "photoFile") {
        contentData[key] = value;
      }
    });

    await updateDoc(doc(db, "content", id), contentData);

    if (photoFile) {
      const existingContentDoc = await getDoc(doc(db, "content", id));
      const existingPhotoURL = existingContentDoc.data().photoURL;

      if (existingPhotoURL) {
        const existingPhotoRef = ref(storage, existingPhotoURL);

        await deleteObject(existingPhotoRef);
      }

      const storageRef = ref(storage, "contentImage/" + id);
      await uploadBytes(storageRef, photoFile);

      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(db, "content", id), {
        homeImage: downloadURL,
      });
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

export { putContent };
