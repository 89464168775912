import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  ButtonGroup,
  CircularProgress,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  MenuItem
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { getPublicCatches, postCatch } from "../../services/catchesService";
import "./Catches.css"

const Catches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [catches, setCatches] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newSubmition, setNewSubmition] = useState({
    catchLocation: "",
    fishLbs: "",
    fishOz: "",
    fishType: "",
    photoFile: null,
    isPublic: false,
    memberName: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageInfo, setSelectedImageInfo] = useState({
    memberName: "",
    fishType: "",
  });
  const [highestRank, setHighestRank] = useState(0)
  const [filteredCatches, setFilteredCatches] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenImageModal = (imageUrl, memberName, fishType) => {
    setSelectedImage(imageUrl);
    setSelectedImageInfo({
      memberName: memberName,
      fishType: fishType,
    });
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
    setSelectedImageInfo({
      memberName: "",
      fishType: "",
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const catchesData = await getPublicCatches();
      catchesData.sort((a, b) => b.orderRank - a.orderRank);
      const maxRank = Math.max(...catchesData.map((item) => item.orderRank));

      if (maxRank > highestRank) {
        setHighestRank(maxRank);
      }
      setCatches(catchesData);
      setFilteredCatches(catchesData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Catches`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setNewSubmition({
      catchLocation: "",
      fishLbs: "",
      fishOz: "",
      fishType: "",
      photoFile: null,
      isPublic: false,
      memberName: "",
    });
    setModalOpen(false);
  };

  const handleInputChange = (field, value) => {
    const formattedValue = value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
      

    setNewSubmition((prevDetails) => ({
      ...prevDetails,
      [field]: formattedValue,
      orderRank: highestRank + 1,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      const formData = new FormData();
      Object.entries(newSubmition).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await postCatch(formData);

      setNewSubmition({
        catchLocation: "",
        fishLbs: 0,
        fishOz: 0,
        fishType: "",
        photoFile: null,
        isPublic: false,
        memberName: "",
      });
      setIsSaving(false);
      fetchData();
    } catch (error) {
      setIsSaving(false);
      console.error("Error adding match:", error);
    }

    handleCloseModal();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewSubmition((prevSubmition) => ({ ...prevSubmition, photoFile: file }));
  };

  const isSubmitDisabled =
    !newSubmition.catchLocation ||
    !newSubmition.fishType ||
    !newSubmition.photoFile ||
    !newSubmition.memberName;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = catches.filter(
      (row) =>
        row.memberName.toLowerCase().includes(query.toLowerCase()) ||
        row.fishType.toLowerCase().includes(query.toLowerCase()) ||
        row.catchLocation.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCatches(filteredData);
  };

  return (
    <div className="page-container">
      <h1>Member Catches</h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ textAlign: "center" }}>
          <p>
            Submit your catch to be proudly displayed on this page!
            <br />
            <Button
              variant="contained"
              onClick={handleOpenModal}
              endIcon={<AddBoxIcon />}
              style={{ marginTop: "10px" }}
            >
              Submit Yours!
            </Button>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              component="div"
              count={catches.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Photo</TableCell>
                  <TableCell>Catch Location</TableCell>
                  <TableCell>Member Name</TableCell>
                  <TableCell>Fish Type</TableCell>
                  <TableCell>Fish Weight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredCatches.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredCatches
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ position: "relative" }}>
                      <div
                        onClick={() =>
                          handleOpenImageModal(
                            row.imgURL,
                            row.memberName,
                            row.fishType
                          )
                        }
                        style={{
                          position: "relative",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        <ZoomInIcon
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "white",
                            background: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        />
                        <img
                          src={row.imgURL}
                          alt="catch image"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{row.catchLocation}</TableCell>
                    <TableCell>{row.memberName}</TableCell>
                    <TableCell>{row.fishType}</TableCell>
                    <TableCell>
                      {row.fishLbs}
                      {row.fishLbs ? "lbs " : ""}
                      {row.fishOz}
                      {row.fishOz ? "oz" : ""}
                      {row.fishLbs || row.fishOz ? "" : "Not Provided"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              component="div"
              count={catches.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      )}

      {/* Zoom Modal */}
      <Dialog open={!!selectedImage} onClose={handleCloseImageModal}>
        <DialogTitle>
          {`${selectedImageInfo.memberName}'s Catch - ${selectedImageInfo.fishType}`}
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Zoomed-in catch image"
              style={{ maxWidth: "100%" }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Submit Modal */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Submit Catch</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                value={newSubmition.memberName}
                required
                onChange={(e) =>
                  handleInputChange("memberName", e.target.value)
                }
                style={{ marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Location</InputLabel>
              <Select
                fullWidth
                required
                value={newSubmition.catchLocation}
                onChange={(e) =>
                  handleInputChange("catchLocation", e.target.value)
                }
              >
                <MenuItem value="Weston">Weston</MenuItem>
                <MenuItem value="Warrens Green">Warrens Green</MenuItem>
                <MenuItem value="Clay End">Clay End</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Fish"
                fullWidth
                required
                value={newSubmition.fishType}
                onChange={(e) => handleInputChange("fishType", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Lbs (can be left blank)"
                type="number"
                value={newSubmition.fishLbs}
                style={{ marginLeft: "30px" }}
                onChange={(e) => handleInputChange("fishLbs", e.target.value)}
              />

              <TextField
                label="Oz (can be left blank)"
                type="number"
                value={newSubmition.fishOz}
                onChange={(e) => handleInputChange("fishOz", e.target.value)}
                style={{ marginLeft: "30px" }}
              />
            </Grid>
            <InputLabel
              style={{ marginLeft: "15px", marginTop: "10px" }}
              required
            >
              Picture
            </InputLabel>
            <Grid item xs={12}>
              <Input
                type="file"
                name="photoFile"
                accept="image/*"
                onChange={handleFileChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup style={{ float: "right" }}>
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ marginLeft: "10px" }}
                  disabled={isSubmitDisabled || isSaving}
                >
                  {isSaving ? "Submitting..." : "Submit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Catches;
