import React, { useState, useEffect } from "react";
import { getContent } from "../../services/contentService";
import CircularProgress from "@mui/material/CircularProgress";

const Rules = () => {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rulesText, setRulesText] = useState("");
  const [rulesUpdated, setRulesUpdated] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const contentData = await getContent();
      setRulesText(contentData[0].rulesText);
      setRulesUpdated(contentData[0].rulesUpdated);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `${currentTitle} - Rules`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  return (
    <div className="page-container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <h1>Priory Angling Club Rules</h1>
          <div style={{ color: "red", fontWeight: "bold", marginBottom: "10px"}}>
            Updated: {rulesUpdated}
          </div>

          <div
            style={{
              whiteSpace: "pre-line",
              maxWidth: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{
              __html: rulesText.replace(/\\n/g, "\n"),
            }}
          />
        </>
      )}
    </div>
  );
};

export default Rules;
