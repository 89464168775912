import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC7yKAVPy68aFY_uYCDrXcSNQYVVDsIzq8",
  authDomain: "priory-angling-club.firebaseapp.com",
  projectId: "priory-angling-club",
  storageBucket: "priory-angling-club.appspot.com",
  appId: "1:960235448741:web:4afc4b573b99aeb968604c",
};

const app = initializeApp(firebaseConfig)

export default app;