import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

import app from "../firebaseConfig";

const db = getFirestore(app);

const postImage = async (formData) => {
  try {
    const memberData = {};
    const storage = getStorage();
    const photoFile = formData.get("photoFile");

    formData.forEach((value, key) => {
      if (key !== "photoFile") {
        memberData[key] = value;
      }
    });

    const storageRef = ref(storage, "contactImage/" + "image");

    await uploadBytes(storageRef, photoFile);

    const downloadURL = await getDownloadURL(storageRef);

    await updateDoc(doc(db, "contact", "image"), {
      photoURL: downloadURL,
    });
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export { postImage };

const getContacts = async () => {
  const querySnapshot = await getDocs(collection(db, "contact"));
  const contactData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return contactData;
};

export { getContacts };

const postContact = async (contact) => {
  try {
    const contactsCollectionRef = collection(db, "contact");
    await addDoc(contactsCollectionRef, {
      title: contact.title,
      name: contact.name,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
    });
  } catch (error) {
    console.error("Error adding contact: ", error);
    throw error;
  }
};

export { postContact };

const deleteContact = async (id) => {
  try {
    const contactDocRef = doc(db, "contact", id);
    const contactDoc = await getDoc(contactDocRef);

    if (contactDoc.exists()) {
      await deleteDoc(contactDocRef);
    } else {
      console.log("Contact not found.");
    }
  } catch (error) {
    console.error("Error deleting contact: ", error);
    throw error;
  }
};

export { deleteContact };
