import React, { useState, useEffect } from "react";
import { getContent, getArticles } from "../../services/contentService";
import CircularProgress from "@mui/material/CircularProgress";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [homeText, setHomeText] = useState("");
  const [homeImage, setHomeImage] = useState("");
  const [homeArticles, setHomeArticles] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const contentData = await getContent();
      const articlesData = await getArticles();
      setHomeText(contentData[0].homeText);
      setHomeImage(contentData[0].homeImage);
      setHomeArticles(articlesData);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const currentTitle = document.title;

    document.title = `Priory Angling Club`;
    fetchData();
    return () => {
      document.title = currentTitle;
    };
  }, []);

  return (
    <div className="page-container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <h1>Priory Angling Club</h1>
          <img
            src={homeImage}
            style={{ maxWidth: "80%", maxHeight: "80%", marginBottom: "10px" }}
            alt="Home Image"
          />

          <div
            style={{
              whiteSpace: "pre-line",
              maxWidth: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{
              __html: homeText.replace(/\\n/g, "\n"),
            }}
          />

          {homeArticles.map((article) => (
            <div
              key={article.id}
              style={{ marginTop: "100px", maxWidth: "80%", textAlign: "left" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {article.title}
              </div>
              <div
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {article.text}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
